import React from "react";
import { Button } from "react-md";
import ReactPlayer from "react-player";

function QuickTour(props) {
  return (
    <div className="students__quickTour" id="quickTour">
      <div className="container students__quickTour-container">
        <div className="students__quickTour-video">
          <ReactPlayer
            url={`https://videos.deltamath.com/public/Intro-to-DeltaMath/Default/HLS/Intro-to-DeltaMath.m3u8`}
            controls
            light={"/files/deltamath-video-placeholder.png"}
            playing={true}
            config={{
              file: {
                hlsOptions: {
                  forceHLS: true,
                },
                attributes: { crossOrigin: 'anonymous' },
                tracks: [
                  {
                    label: "English",
                    kind: 'subtitles',
                    src: 'https://videos.deltamath.com/public/Intro-to-DeltaMath/Default/Subtitles/Intro-to-DeltaMath.mp4.vtt',
                    srcLang: 'en',
                    default: false
                  },
                ]
              }
            }}
            style={
              {
                margin: "0 auto 3rem auto",
                width: "100%"
              }
            }
          />
        </div>
        <div className="students__quickTour-info">
          <h2 className="students__quickTour-header1">Watch a quick tour of</h2>
          <h2 className="students__quickTour-header2">
            DeltaMath for Students.
          </h2>
          <Button
            className="students__quickTour-button"
            theme="primary"
            themeType="outline"
            onClick={props.enable}
          >
            Register
          </Button>
        </div>
      </div>
    </div>
  );
}

export default QuickTour;
